export const orderList = [
  { id: '', title: '全部', label: 'total_count', count: 0 },
  { id: 1, title: '进行中', label: 'processing', count: 0 },
  { id: 2, title: '已完成', label: 'completed', count: 0 },
]
export const dispatchList = [
  { id: '', title: '全部', label: 'total_count', count: 0 },
  { id: 0, title: '未指派', label: 'undispatch', count: 0 },
  { id: 3, title: '已停止', label: 'cessation', count: 0 },
  { id: 1, title: '进行中', label: 'processing', count: 0 },
  { id: 2, title: '已完成', label: 'completed', count: 0 },
  { id: 4, title: '已归档', label: 'lock_orders', count: 0 },
]
export const orderStatusMapColor = {
  0: {
    color: '#ED7B2F',
    text: '未指派',
  }, // 未指派
  1: {
    color: '#6476FF',
    text: '进行中',
  }, // 进行中
  2: {
    color: '#00A870 ',
    text: '已完成',
  }, // 已完成
  3: {
    color: 'red ',
    text: '已停止',
  }, // 已停止
}
export const orderFromList = {
  guigui: '柜柜',
  seeyard: '小程序',
}
export const orderExcelHeads = [
  'A1',
  'B1',
  'C1',
  'D1',
  'E1',
  'F1',
  'G1',
  'H1',
  'I1',
  'J1',
  'K1',
  'L1',
  'M1',
]
export const orderExcelKeys = [
  '服务需求（必填）',
  '项目所在省（必填）',
  '项目所在市（必填）',
  '项目所在区（必填）',
  '详细地址（必填）',
  '项目联系人（必填）',
  '项目联系人电话（必填）',
  '创建时间（必填）',
  '期望交付时间（必填）',
  '服务站账号（必填）',
  '期望开始时间',
  '预估投影面积',
  '服务要求注意事项',
]
// 编辑模式
export const PATTERN_MAP = {
  EDIT: 'edit',
  VIEW: 'view',
}

export const serviceTypeList = {
  0: '-',
  1: 'CAD图纸拆单',
  2: '草图拆单',
  3: '审单',
  4: '画CAD图纸',
  5: '一口价',
}
export const seeyardOrderStatusMap = {
  consult: 1, //咨询中
  unquote: 2, //待报价
  quoted: 3, //已报价
  payment: 4, //已付款
  unaccept: 5, //待验收
  accept: 6, //已验收
  refund: 7, //已退款
  afterSales: 8, //售后中
}
