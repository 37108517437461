export const orderTableColumns = [
  {
    title: '订单名称',
    dataIndex: 'buyer_address',
    scopedSlots: { customRender: 'buyer_address' },
  },
  {
    title: '下单联系人',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '订单状态',
    dataIndex: 'dispatch_status',
    scopedSlots: { customRender: 'dispatch_status' },
  },
  {
    title: '订单来源',
    dataIndex: 'order_from',
    scopedSlots: { customRender: 'order_from' },
  },
]
// 字段有变动
export const orderStatusColumns = [
  {
    title: '订单名称',
    dataIndex: 'buyer_address',
    scopedSlots: { customRender: 'buyer_address' },
  },
  {
    title: '下单客户',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '客户账号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' },
  },
  {
    title: '截止时间',
    dataIndex: 'expire_time',
    scopedSlots: { customRender: 'expire_time' },
  },
  {
    title: '订单状态',
    dataIndex: 'dispatch_status',
    scopedSlots: { customRender: 'dispatch_status' },
  },
  // {
  //   title: '订单来源',
  //   dataIndex: 'order_from',
  //   scopedSlots: { customRender: 'order_from' },
  // },
  // 服务站暂时注释
  {
    title: '服务站',
    dataIndex: 'station_id',
    scopedSlots: { customRender: 'station_id' },
  },
  {
    title: '是否延期',
    dataIndex: '',
    scopedSlots: { customRender: 'is_expire' },
  },
  {
    title: '是否结算',
    dataIndex: 'financial_url',
    scopedSlots: { customRender: 'financial_url' },
  },
]

export const cloudOrderTableColumns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'index' },
  },
  {
    title: '下单时间',
    dataIndex: 'create_time',
  },
  {
    title: '客户名称',
    dataIndex: 'user_name',
    scopedSlots: { customRender: 'user_name' },
  },
  {
    title: '订单名称',
    dataIndex: 'order_name',
    scopedSlots: { customRender: 'order_name' },
  },
  {
    title: '服务类型',
    dataIndex: 'order_type',
    scopedSlots: { customRender: 'order_type' },
  },
  {
    title: '客户账号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '销售人员',
    dataIndex: 'salesman_id',
    scopedSlots: { customRender: 'salesman_id' },
  },
  {
    title: '投影面积',
    dataIndex: 'area',
    scopedSlots: { customRender: 'area' },
  },
  {
    title: '单价',
    dataIndex: 'price',
    scopedSlots: { customRender: 'price' },
  },
  {
    title: '总价',
    dataIndex: 'quote_price',
    scopedSlots: { customRender: 'quote_price' },
  },
  {
    title: '交付时间',
    dataIndex: 'delivery_time',
    scopedSlots: { customRender: 'delivery_time' },
  },
  {
    title: '付款时间',
    dataIndex: 'pay_time',
    scopedSlots: { customRender: 'pay_time' },
  },
  {
    title: '服务站',
    dataIndex: 'station_id',
    scopedSlots: { customRender: 'station_id' },
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
    scopedSlots: { customRender: 'order_status' },
  },
  {
    title: '备注',
    dataIndex: 'order_note',
    scopedSlots: { customRender: 'order_note' },
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' },
  },
]
